import React, { useMemo } from "react";
import { CellProps } from "./Cell";
import { BASE_COLUMN_TYPES, ColumnDataMap } from "../config/types";
import { Csku, Typography } from "@commonsku/styles";

type TColumn = typeof BASE_COLUMN_TYPES.Text;
const TextCell = ({
  column,
  row,
  className,
  onClick,
  textStyle = {},
  style = {},
}: CellProps<TColumn>) => {
  const { accessorKey, dataTransform } = column;

  const { text, tooltipText } = useMemo<ColumnDataMap<TColumn>>(() => {
    const rawData = row.original[accessorKey];

    if (dataTransform) return dataTransform(rawData);

    const key = Object.keys(rawData);
    if (key.length !== 1)
      throw new Error(
        "TextCell can only handle one key if dataTransform is not provided",
      );
    return { text: rawData[key[0]] };
  }, [accessorKey, dataTransform, row.original]);

  const formattedTooltipText = useMemo<string>(() => {
    if (!tooltipText) return "";

    return tooltipText
      .split("\n")
      .map((eachLine) => eachLine.trim())
      .filter((eachLine) => eachLine !== "")
      .join("<br/>");
  }, [tooltipText]);

  // We only want to render tooltip if the toolTipText is available
  const tooltipAttributes = useMemo(() => {
    if (formattedTooltipText) {
      return {
        "data-for": "report-table-tooltip",
        "data-tip": formattedTooltipText,
        "data-multiline": formattedTooltipText.includes("<br/>"),
      };
    }
    return null;
  }, [formattedTooltipText]);

  return (
    <Csku className={className} onClick={onClick}>
      <div style={style} {...tooltipAttributes}>
        <Typography.Span
          style={{
            display: "block",
            alignContent: "center",
            color: style.color,
            ...textStyle,
          }}
        >
          {text}
        </Typography.Span>
      </div>
    </Csku>
  );
};

export default TextCell;
